import React, { FC } from 'react';
import { graphql } from 'gatsby';
import useYotpo from 'hooks/useYotpo';
import { Layout } from 'layout';
import Banner from 'components/Banner';
import { ReviewsPageComponentProps } from './models';
import './ReviewsPageMain.scss';
import './ReviewsPageRTL.scss';

const ReviewsPage: FC<ReviewsPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoReviews: {
      seoMetaKeywords,
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
      title,
      url,
    },
  },
}) => {
  useYotpo();

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoMetaDescription,
        seoExternalHreflangs,
      }}
      className="reviews-page"
    >
      <Banner url={url} key={title} title={title} />

      <div className="reviews-page__container">
        <div id="yotpo-testimonials-custom-tab" />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ReviewsPage($url: String = "", $lang: String) {
    umbracoReviews: umbracoReviews(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaTitle
      seoCanonicalUrl
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      title
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default ReviewsPage;
